<template>
    <!-- eslint-disable -->
    <div class="grid">
        <div class="col-12">
            <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
        </div>
        <div class="col-12 p-fluid">
            <div class="card">
                <div class="grid formgrid mb-4" style="margin: 1em 0 0 0;">
                    <div class="col-6">
                        <div class="col-12 mb-5 lg:col-12 lg:mb-0">
                            <p>Type *</p>
                            <!-- <InputText type="text" v-model="inputType" /> -->
                            <AutoComplete v-model="inputType" :suggestions="filteredTypes" @complete="searchTypes($event)" field="" />
                        </div>
                        <div class="col-12 mb-5 lg:col-12 lg:mb-0">
                        </div>
                        <div class="col-12 mb-5 mt-3 lg:col-12 lg:mb-0">
                            <p>Name *</p>
                            <InputText type="text" v-model="inputName" />
                        </div>
                        <div class="col-12 mb-5 lg:col-12 lg:mb-0">
                        </div>
                        <div class="col-12 mb-5 mt-3 lg:col-12 lg:mb-0">
                            <p>Return</p>
                            <InputText type="text" v-model="inputReturn" />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="col-12 mb-5 lg:col-12 lg:mb-0">
                            <p>Description</p>
                            <InputText type="text" v-model="inputDesc" />
                        </div>
                        <div class="col-12 mb-5 lg:col-12 lg:mb-0">
                        </div>
                        <div class="col-12 mb-1 mt-2 lg:col-12 lg:mb-0">
                            <br>
                            <p>Active *</p>
                            <InputSwitch v-model="inputActive" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 p-fluid">
        <div class="col-7 mt-2 lg:col-4 lg:mb-0">
                <Button icon="pi pi-arrow-left" class="p-button-warning" label="Return" @click="returnPage()"></Button>
            </div>
        </div>
        <div class="col-6 p-fluid">
            <div class="col-12 mt-2 lg:col-12 lg:mb-0">
                <Button icon="pi pi-check" label="Update" @click="onSubmit()"></Button>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import LovService from '../../service/LovService'
export default {
    data() {
        return {
            breadcrumbHome: {icon: 'pi pi-home', to: '/dashboard'},
		    breadcrumbItems: [
			    {label:'LOV Dynamic', to: '/mgm/settings/lovdynamic'},
			    {label:'Edit LOV Data'},
		    ],
            ambilId: null,
            inputType: null,
            inputName: null,
            inputReturn: null,
            inputDesc: null,
            inputActive: true,
            valueActive: null,
            typevalue: [],
            filteredTypes: null

        }
    },
    mounted() {
        LovService.getAllData().then((respType) => {
            this.typevalue = [];
            var typeTmp = null;
            for (const i of respType.data.data) {
                if (typeTmp != JSON.stringify(i["type"])) {
                    this.typevalue.push(i["type"]);
                }
                typeTmp = JSON.stringify(i["type"]);
            }
        })
        this.name = this.$route.params.name;
        LovService.getDetailValue(this.name).then(async (result) => {
            this.ambilId = [];
            this.inputType = [];
            this.inputName = [];
            this.inputReturn = [];
            this.inputDesc = [];
            this.valueActive = [];
            result.data.data.forEach((element) => {
                this.ambilId.push(element.id);
                this.inputType.push(element.type);
                this.inputName.push(element.name);
                this.inputReturn.push(element.value);
                this.inputDesc.push(element.db_instance);
                if (element.active === 1) {
                    this.valueActive.push(element.active);
                    this.inputActive = true;
                } else if (element.active === 0) {
                    this.valueActive.push(element.active);
                    this.inputActive = false;
                }
            })
        });
    },
    methods: {
        returnPage() {
            this.$router.push('lovdynamic')
        },
        showValues() {
            
        },
        searchTypes(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredTypes = [...this.typevalue];
                } else {
                    this.filteredTypes = this.typevalue.filter((item) => {
                        return item.toLowerCase().match(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        async onSubmit() {
            if (this.inputActive === true) {
                this.valueActive = 1;
            } else if (this.inputActive === false) {
                 this.valueActive = 0;
            }
            if (this.inputType === null || this.inputActive === null || this.inputName === null) {
                window.alert("Harap isi kolom yang berbintang")
            }
            let payload = {
                id: this.ambilId,
                name: this.inputName.toString().toUpperCase(),
                db_instance: this.inputDesc.toString().toUpperCase(),
                value: this.inputReturn.toString().toUpperCase(),
                type: this.inputType.toString().toUpperCase(),
                active: this.valueActive
            };
            LovService.updateValue(payload).then((result) => {
            //
              if (result.status === 200) {
                      this.$router.push('lovdynamic');
              } else {
                //
              }
            },
            // (err) => {
            //   
            //   this.showTopCenterErr(err.error.data);
            // }
          );
        }
    }
}
</script>